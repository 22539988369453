import { Icon, Toolbar, Toast } from '@plone/volto/components';
import {
  getBaseUrl,
  Helmet,
  toPublicURL,
  messages as voltoMessages,
} from '@plone/volto/helpers';
import backSVG from '@plone/volto/icons/back.svg';
//import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Portal } from 'react-portal';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { Button, Container, Segment, Header, Input } from 'semantic-ui-react';
import { getSharingLink, shares } from '~/actions/Sharing/shares';
import UserSearch from '~/components/Sharing/userSearch';
import UserList from '~/components/Sharing/userList';
import sharingSVG from '@plone/volto/icons/share.svg';
import { getSharing, updateSharing } from '@plone/volto/actions';
import { find, map } from 'lodash';

const messages = defineMessages({
  searchForUserOrGroup: {
    id: 'Enter the email address to share the workspace with',
    defaultMessage: 'Enter the email address to share the workspace with',
  },
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  sharing: {
    id: 'Sharing',
    defaultMessage: 'Sharing',
  },
  user: {
    id: 'User',
    defaultMessage: 'User',
  },
  group: {
    id: 'Group',
    defaultMessage: 'Group',
  },
  globalRole: {
    id: 'Global role',
    defaultMessage: 'Global role',
  },
  inheritedValue: {
    id: 'Inherited value',
    defaultMessage: 'Inherited value',
  },
  permissionsUpdated: {
    id: 'Permissions updated',
    defaultMessage: 'Permissions updated',
  },
  permissionsUpdatedSuccessfully: {
    id: 'Permissions have been updated successfully',
    defaultMessage: 'Permissions have been updated successfully',
  },
  shareWorkspaceModalTitle: {
    id: 'Share Workspace',
    defaultMessage: 'Share Workspace',
  },
});

class SharingComponent extends Component {
  static propTypes = {
    shares: PropTypes.func.isRequired,
    getSharing: PropTypes.func.isRequired,
    getSharingLink: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    login: PropTypes.string,
    entries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        login: PropTypes.string,
        roles: PropTypes.object,
        title: PropTypes.string,
        type: PropTypes.string,
      }),
    ).isRequired,
  };

  static defaultProps = {
    inherit: null,
    login: '',
  };

  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.shareWorkspace = this.shareWorkspace.bind(this);
    this.workspaceRoleEntries = this.workspaceRoleEntries.bind(this);
    this.workspaceFilterRoles = this.workspaceFilterRoles.bind(this);
    this.workspaceRoleValue = this.workspaceRoleValue.bind(this);
    this.onShareWorkspaceSuccess = this.onShareWorkspaceSuccess.bind(this);
    this.onShareWorkspaceFailure = this.onShareWorkspaceFailure.bind(this);
    this.convertSharing = this.convertSharing.bind(this);
    this.state = {
      isClient: false,
      getSharingLink: { link: '' },
      sharing_link: '',
      selectedUsers: [],
      form: {
        permission: 'View Workspace',
        customEmail: '',
        sendLinkToAccessContent: true,
      },
      availableRoles: [],
      entries: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.props.getSharing(getBaseUrl(this.props.pathname));
    this.props.getSharingLink(getBaseUrl(this.props.pathname));
    this.setState({ isClient: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.sharingRequest.get.loading &&
      nextProps.sharingRequest.get.loaded
    ) {
      this.setState({
        entries: this.convertSharing(nextProps.sharingRequest.data.entries),
      });
      this.setState({
        availableRoles: this.workspaceFilterRoles(
          nextProps.sharingRequest.data.available_roles,
        ),
      });
    }
    if (this.props.createRequest.loading && nextProps.createRequest.loaded) {
      this.onShareWorkspaceSuccess();
    }
    if (this.props.createRequest.loading && nextProps.createRequest.error) {
      this.onShareWorkspaceFailure(nextProps.createRequest.error);
    }
    if (
      this.props.linkRequest.shares.loading &&
      nextProps.linkRequest.shares.loaded
    ) {
      this.setState({
        sharing_link: getBaseUrl('') + '/share/' + nextProps.linkRequest.link,
      });
    }
    if (
      this.props.updateRequest.loading === false &&
      nextProps.updateRequest.loading
    ) {
      this.setState({ loading: true });
    }
    if (
      !this.props.createRequest.loading &&
      this.props.updateRequest.loading &&
      nextProps.updateRequest.loaded
    ) {
      //this.props.getSharing(getBaseUrl(this.props.pathname), this.state.search);
      toast.success(
        <Toast
          success
          title={this.props.intl.formatMessage(messages.permissionsUpdated)}
          content={this.props.intl.formatMessage(
            messages.permissionsUpdatedSuccessfully,
          )}
        />,
      );
      this.setState({ loading: false });
      this.props.getSharing(getBaseUrl(this.props.pathname));
    }
  }

  convertSharing(entries) {
    let filtered_entries = entries.filter((entry) => {
      if (entry.type === 'group') {
        return false;
      }
      return true;
    });
    return map(filtered_entries, (entry) => {
      const values = find(entries, { id: entry.id });
      return {
        ...entry,
        roles: values ? values.roles : entry.roles,
      };
    });
  }

  handleInputChange(event, { name, value, checked }) {
    this.setState({
      form: {
        ...this.state.form,
        [name]: checked || value,
      },
    });
  }

  shareWorkspace() {
    const userData = {
      email: this.state.selectedUsers,
      roles: this.workspaceRoleEntries(this.state.form.permission),
      send_link: this.state.form.sendLinkToAccessContent,
      customEmail: this.state.form.customEmail,
    };
    this.props.shares(getBaseUrl(this.props.pathname), userData);
  }
  onShareWorkspaceSuccess() {
    this.setState({
      addUserError: undefined,
      showRemoveUserDialog: false,
      userToRemove: null,
      search: '',
      selectedUsers: [],
    });
    this.props.getSharing(getBaseUrl(this.props.pathname), '');

    toast.success(
      <Toast
        success
        title={this.props.intl.formatMessage(voltoMessages.success)}
        content={this.props.intl.formatMessage(voltoMessages.userCreated)}
      />,
    );
  }
  onShareWorkspaceFailure(error) {
    this.setState({
      loading: false,
      addUserError: undefined,
      showRemoveUserDialog: false,
      userToRemove: null,
      search: '',
      selectedUsers: [],
    });
    this.props.getSharing(getBaseUrl(this.props.pathname), '');
    const content =
      <>
        <strong>
          {error.response.statusCode} - {error.response.statusText}
        </strong>
        <br/>
          {error.response.body.type}
        <br/>{error.response.body.message.split("\n").join("<br/>")}
      </>;
    toast.error(
      <Toast
        error
        title={this.props.intl.formatMessage(voltoMessages.error)}
        content={content}
      />,
    );
  }

  workspaceRoleEntries(role) {
    if (role === 'Share Workspace') {
      return {
        'View Workspace': true,
        'Edit Workspace': true,
        'Share Workspace': true,
      };
    }
    if (role === 'Edit Workspace') {
      return {
        'View Workspace': true,
        'Edit Workspace': true,
      };
    }
    if (role === 'View Workspace') {
      return { 'View Workspace': true };
    }
    return {};
  }

  workspaceFilterRoles(available_roles) {
    if (available_roles.length === 0) {
      return [];
    }
    let wanted_roles = available_roles.filter((item) => {
      // Remove all roles except the custom ones
      if (
        !['View Workspace', 'Edit Workspace', 'Share Workspace'].includes(
          item.id,
        )
      ) {
        return false;
      }
      if (item.id === 'View Workspace') {
        item.title = 'Can View';
      }
      if (item.id === 'Edit Workspace') {
        item.title = 'Can View and Edit';
      }
      if (item.id === 'Share Workspace') {
        item.title = 'Can View, Edit and Share';
      }
      return true;
    });
    // Rearrange roles
    const reader = wanted_roles[2];
    wanted_roles.splice(2, 1);
    wanted_roles.splice(0, 0, reader);
    return wanted_roles;
  }

  workspaceRoleValue(user) {
    if (user.roles['Share Workspace'] === true) {
      return 'Share Workspace';
    }
    if (user.roles['Edit Workspace'] === true) {
      return 'Edit Workspace';
    }
    if (user.roles['View Workspace'] === true) {
      return 'View Workspace';
    }
  }

  render() {
    return (
      <Container id="page-sharing">
        <Helmet title={this.props.intl.formatMessage(messages.sharing)} />
        <Segment.Group raised>
          <Segment className="primary">
            <Icon name={sharingSVG} size="30px" />
            <span style={{ position: 'relative', bottom: '10px' }}>
              <FormattedMessage
                id="Sharing for {title}"
                defaultMessage="Sharing for {title}"
                values={{ title: <q>{this.props.title}</q> }}
              />
            </span>
          </Segment>
          <Segment>
            <h2>Invite Users</h2>
          </Segment>
          <Segment basic compact>
            <UserSearch
              availableRoles={this.state.availableRoles}
              selectedUsers={this.state.selectedUsers}
              updateUsers={(users) => {
                this.setState({ selectedUsers: users });
              }}
              onShare={this.shareWorkspace}
              emailNotification={true}
              handleInputChange={this.handleInputChange}
              entries={this.state.entries}
              form={this.state.form}
            />
          </Segment>
          <Segment basic compact>
            <h2>Shared With</h2>
            <UserList
              loading={this.state.loading}
              getRoleEntries={(role) => this.workspaceRoleEntries(role)}
              getRoleValue={(user) => this.workspaceRoleValue(user)}
              availableRoles={this.state.availableRoles}
              entries={this.state.entries}
              updateSharing={this.props.updateSharing}
            />
          </Segment>
          <Segment>
            <Header as="h2">Sharing link</Header>
            <p className="help">
              The following link can be used to view the shared content. Only
              users listed above will be allowed to see the content.
            </p>
            <Input
              style={{ width: '90%' }}
              readOnly
              action={
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    window.navigator.clipboard.writeText(
                      toPublicURL(this.state.sharing_link),
                    );
                  }}
                >
                  Copy link
                </Button>
              }
              value={toPublicURL(this.state.sharing_link)}
            />
          </Segment>
        </Segment.Group>
        {this.state.isClient && (
          <Portal node={document.getElementById('toolbar')}>
            <Toolbar
              pathname={this.props.pathname}
              hideDefaultViewButtons
              inner={
                <Link
                  to={`${getBaseUrl(this.props.pathname)}`}
                  className="item"
                >
                  <Icon
                    name={backSVG}
                    className="contents circled"
                    size="30px"
                    title={this.props.intl.formatMessage(messages.back)}
                  />
                </Link>
              }
            />
          </Portal>
        )}
      </Container>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
  connect(
    (state, props) => ({
      entries: state.sharing.data.entries,
      updateRequest: state.sharing.update,
      linkRequest: state.getSharingLink,
      sharingRequest: state.sharing,
      createRequest: state.shares.shares,
      pathname: props.location.pathname,
      title: state.content.data.title,
      login: state.userSession.token
        ? jwtDecode(state.userSession.token).sub
        : '',
    }),
    { shares, getSharingLink, getSharing, updateSharing },
  ),
)(SharingComponent);
