/**
 * Contents upload modal.
 * @module components/manage/Contents/ContentsUploadModal
 */

import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Button,
  Header,
  Icon as IconOld,
  Modal,
  Table,
  Segment,
  Progress,
  Message
} from 'semantic-ui-react';
import loadable from '@loadable/component';
import { map } from 'lodash';
import filesize from 'filesize';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { FormattedRelativeDate, Icon } from '@plone/volto/components';
import TusUploady from "@rpldy/tus-uploady";
import {
  useUploady,
  useRequestPreSend,
  useBatchAddListener,
  useItemProgressListener,
  useItemStartListener,
  useAbortAll,
  useItemFinishListener,
  useItemErrorListener
} from "@rpldy/uploady";
import { getBaseUrl } from '@plone/volto/helpers/Url/Url';
const Dropzone = loadable(() => import('react-dropzone'));
import readySVG from '@plone/volto/icons/ready.svg';
import mistakeSVG from '@plone/volto/icons/mistake.svg';

const messages = defineMessages({
  close: {
    id: 'Close',
    defaultMessage: 'Close',
  },
  upload: {
    id:
      '{count, plural, one {Upload {count} file} other {Upload {count} files}}',
    defaultMessage:
      '{count, plural, one {Upload {count} file} other {Upload {count} files}}',
  },
  complete: {
    id: 'Upload Complete',
    defaultMessage: 'Upload Complete',
  },
  error: {
    id: 'Upload Failed',
    defaultMessage: 'Upload Failed',
  }
});

/**
 * ContentsUploadModal class.
 * @class ContentsUploadModal
 * @extends Component
 */
class ContentsUploadModal extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs ContentsUploadModal
   */
  constructor(props) {
    super(props);
    this.state = {
      tusfiles: [],
      started: {},
      finished: {},
      error: {},
      uploads: false,
    };
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const BrowseButton = () => {
      useRequestPreSend(({ items, options }) => {
        let ctype = 'File';
        if ( items[0]['file']['type'].startsWith('image') ){
          ctype = 'Image';
        }
        if ( items[0]['file']['type'].startsWith('video') ){
          ctype = 'Video';
        }

        return {
          options: { params:
            { filename: items[0]['file']['name'],
              "Content-type": items[0]['file']['type'],
              "@type": ctype
            }
          }
        };

      });
      const uploady = useUploady();

      const onClick = () => {
        uploady.showFileUpload({autoUpload: true});
      }
      return <button onClick={onClick} className="ui button primary">
        <FormattedMessage
          id="Browse"
          defaultMessage="Browse"
        />
      </button>;
    }

    const CloseButton = () => {
      const abortAll = useAbortAll();
      const onClose = () => {
        abortAll();
        this.props.onClose(this.state.uploads);
        this.setState({
          tusfiles: [],
        });
      }
      return (
        <Button
            basic
            circular
            secondary
            icon="remove"
            aria-label={this.props.intl.formatMessage(messages.close)}
            title={this.props.intl.formatMessage(messages.close)}
            floated="right"
            size="big"
            onClick={onClose}
          />
      )
    }

    const ItemProgress = memo(({ id }) => {
      const listener = useItemProgressListener(id)
      const actual_progress = listener?.completed || 0
      const completed = actual_progress - (actual_progress / 90);

      useItemStartListener((item) =>{
        const started = this.state.started;
        const finished = this.state.finished;
        const error = this.state.error;
        started[id] = true;
        finished[id] = false;
        error[id] = false;
        this.setState({started: started});
        this.setState({finished: finished});
        this.setState({error: error});
      }, id);

      useItemFinishListener((item) =>{
        const started = this.state.started;
        const finished = this.state.finished;
        const error = this.state.error;
        started[id] = false;
        finished[id] = true;
        error[id] = false;
        this.setState({started: started});
        this.setState({finished: finished});
        this.setState({error: error});
      }, id)

      useItemErrorListener((item) =>{
        const started = this.state.started;
        const finished = this.state.finished;
        const error = this.state.error;
        started[id] = false;
        finished[id] = false;
        error[id] = item.uploadResponse;
        console.log("Error uploading file: ", item.uploadResponse);
        this.setState({started: started});
        this.setState({finished: finished});
        this.setState({error: error});
      })

      return (
        <>
          {this.state.started[id] ? <Progress
            aria-label={`Progress ${completed}%`}
            percent={completed}
            indicating
            style={{marginTop: "1.5em"}} />
            : null}
          {this.state.finished[id] ? <Icon
              name={readySVG}
              aria-label='Upload Finished'
              color="green"
              size="30px"
              title={this.props.intl.formatMessage(messages.complete)}
          />: null}
          {this.state.error[id] ? <>
            <Icon
              name={mistakeSVG}
              aria-label="Upload Failed"
              color="red"
              title={this.props.intl.formatMessage(messages.error)}
            />

            </>: null}
        </>
      );
    });

    const DropZoneArea = () => {
      const { upload } = useUploady();

      useBatchAddListener((batch) => {
        this.setState({tusfiles: this.state.tusfiles.concat(batch.items)});
        const started = this.state.started;
        started[batch.items[0].file.id] = true;
        this.setState({started: started});
        this.setState({uploads: true});
      });

      const onDrop = (files) => {
        upload(files, {autoUpload: true});
      }

      return (
        <Dropzone
          onDrop={onDrop}
          className="dropzone"
          noDragEventsBubbling={true}
          multiple={true}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: 'dashed' })}>
              <Segment>
                <Table basic="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <FormattedMessage
                          id="Drag and drop files from your computer onto this area or click the “Browse” button."
                          defaultMessage="Drag and drop files from your computer onto this area or click the “Browse” button."
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <BrowseButton/>

                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Segment>
            </div>
          )}
        </Dropzone>
      );
    }

    const RemoveButton = ({file, index}) => {
      const { abort } = useUploady();

      const removeFile = () => {
        abort(file.id);
        this.setState({
          tusfiles: this.state.tusfiles.filter(
            function(key) { return key.id != file.id})
        });
      };

      return (
        !this.state.finished[file.id] ?
          <IconOld
            name="close"
            value={index}
            link
            onClick={removeFile}
          />
        : null );
    }

    const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    const uploadURL = '/++api++' + getBaseUrl(location?.pathname || '') + '/@tus-upload';

    return (
      this.props.open && (
        <Modal open={this.props.open}>
          <TusUploady
              destination={
                { url: uploadURL,
                  headers: {
                    "Authorization": `Bearer ${authToken}`,
                    accept: 'application/json'}
              }}
              chunkSize={52428800}  // 50MB
              sendDataOnCreate={false}
              sendWithFormData
              withCredentials={true}>
          <Header>
            <FormattedMessage id="Upload files" defaultMessage="Upload files" />
          </Header>
          <Modal.Content>
              <DropZoneArea />
              {this.state.tusfiles.length > 0 && (
                <Table compact singleLine>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={8}>
                        <FormattedMessage
                          id="Filename"
                          defaultMessage="Filename"
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell width={4}>
                        <FormattedMessage
                          id="Last modified"
                          defaultMessage="Last modified"
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell width={4}>
                        <FormattedMessage
                          id="File size"
                          defaultMessage="File size"
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell width={4}>
                        <FormattedMessage id="Progress"
                          defaultMessage="Progress" />
                      </Table.HeaderCell>
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {map(this.state.tusfiles, (file, index) => (
                      <>
                      <Table.Row className="upload-row" key={file.id}>
                        <Table.Cell>{file.file.name}
                        </Table.Cell>
                        <Table.Cell>
                          {file.file.lastModifiedDate && (
                            <FormattedRelativeDate
                              date={file.file.lastModifiedDate} />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {filesize(file.file.size, { round: 0 })}
                        </Table.Cell><Table.Cell
                          textAlign="center"
                          verticalAlign="middle"
                          style={{ height: "6em" }}>
                          <ItemProgress id={file.id} />
                        </Table.Cell><Table.Cell>
                          <RemoveButton index={index} file={file} />
                        </Table.Cell>
                      </Table.Row>
                       {this.state.error[file.id] ? <>
                        <Table.Row>
                          <Table.Cell colSpan="5">
                            <Message error>
                              <Message.Header>Upload Failed</Message.Header>
                              <p>{this.state.error[file.id]}</p>
                            </Message>
                          </Table.Cell>
                        </Table.Row>
                       </> : null }</>
                    ))}
                  </Table.Body>
                </Table>
              )}
          </Modal.Content>
          <Modal.Actions>
            <CloseButton/>
          </Modal.Actions>
          </TusUploady>
        </Modal>
      )
    );
  }
}

export default compose(
  injectIntl,
)(ContentsUploadModal);
