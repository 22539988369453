import { ConditionalLink, Icon, Tags } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import fileSVG from '@plone/volto/icons/attachment.svg';
import imageSVG from '@plone/volto/icons/image.svg';
import pageSVG from '@plone/volto/icons/page.svg';
import videoSVG from '@plone/volto/icons/video.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { Header, List } from 'semantic-ui-react';

const contentTypeIconMapping = {
  Video: videoSVG,
  Image: imageSVG,
  File: fileSVG,
  Document: pageSVG,
};

const DefaultTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  return (
    <>
      <List relaxed>
        {items.map((item) => {
          return (
            <List.Item>
              {contentTypeIconMapping[item['@type']] ? (
                // <List.Icon name='github' size='large' verticalAlign='middle' />
                <List.Icon>
                  <Icon
                    name={contentTypeIconMapping[item['@type']]}
                    size="15px"
                    color="#b8c6c8"
                  />
                </List.Icon>
              ) : null}
              <List.Content>
                <List.Header as="a" href={flattenToAppURL(item['@id'])}>
                  {item.title ? item.title : item.id}
                </List.Header>
                <List.Description as="a">{item.description}</List.Description>
                {item['@type'] === 'File' ? (
                  <ConditionalLink
                    to={`${item['@id']}/@@download/file`}
                    condition={!isEditMode}
                  >
                    Download "{item.title ? item.title : item.id}"
                  </ConditionalLink>
                ) : null}
                {item.subjects && item.subjects.length > 0 ? (
                  <Tags tags={item.subjects} />
                ) : null}
              </List.Content>
            </List.Item>
          );
        })}
      </List>

      {link && <div className="footer">{link}</div>}
    </>
  );
};
DefaultTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default DefaultTemplate;
