/**
 * Routes.
 * @module routes
 */

import { App } from '@plone/volto/components';
import { defaultRoutes } from '@plone/volto/routes';
import config from '@plone/volto/registry';
import EmailLoginComponent from './components/Auth/EmailLogin';
//import LoginCode from './components/Auth/LoginCode';
import Logout from './components/Auth/Logout';
import Share from './components/Sharing/share';
import Admin from './components/Admin/Admin';

/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const routes = [
  {
    path: '/',
    component: App, // Change this if you want a different component
    routes: [
      {
        path: '/login',
        component: EmailLoginComponent,
        exact: true,
      },
      {
        path: '/**/login',
        component: EmailLoginComponent,
        exact: true,
      },
      {
        path: '/login/:code?/:otp?',
        component: EmailLoginComponent,
        exact: true,
      },
      {
        path: '/**/login/:code?/:otp?',
        component: EmailLoginComponent,
        exact: true,
      },
      /*{
        path: '/logincode',
        component: LoginCode,
        exact: true,
      },*/
      {
        path: '/logout',
        component: Logout,
        exact: true,
      },
      {
        path: '/share/:code',
        component: Share,
        exact: true,
      },
      {
        path: '/admin',
        component: Admin,
        exact: true,
      },
      ...(config.addonRoutes || []),
      ...defaultRoutes,
    ],
  },
];

export default routes;
